.shortsearchform {

  .shortsearch-transaction{
    font-size:15px;
    padding: 15px 25px;
    background-color: #fff;
    border:none;
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom:15px;

    &.active{
      background-color: @mycolor-red;
      color: #fff;
    }
  }

  .shortsearch-inner-wrapper{
    background-color: #fff;
    //margin-top:20px;
    width: auto;
    margin:0 auto 0 auto;
    padding: 15px 15px 5px 15px;
    display:inline-block;
  }


  .selectize-dropdown-content{
    text-align:left;
  }
  .form-group{
    vertical-align:top;
  }
  .selectize-input, .selectize-control.multi .selectize-input.has-items {
    padding: 12px;
    //height: 64px;
    border-radius:0;
   // margin-top:7px;
   // border:none;
    min-width: 250px;
   // max-width: 400px;
    text-align: left;
    input{
      //line-height: 40px !important;
    }
  }

  .search-inner{
    display: table;
    table-layout: fixed;
    width: 100%;
    vertical-align: top;
  }

  .aditional-search{
    background: #fff;
    margin-left:15px;
    display: inline-block;
    vertical-align:top;
    .btn{
      margin:0 !important;
      background:@mycolor-red;
      font-size: 14px;
      padding-left:25px !important;
      padding-right:25px !important;
      height: 45px !important;
      border-radius:2px;
    }
  }
}

::placeholder{
  color: #999;
}

.half-splash{
  .search-form-wrapper{
    padding: 30px;
    text-align:center;
    width: 90%;
    margin:0 auto;
    .search-inner{
   //   background: rgba(0,0,0,.4);
      border: none;
    }


  }

  .search-form-wrapper .search-inner input.btn-primary{
    width: auto;
    margin: 0 auto;
    //margin-top: 20px;
    font-size: 14px;
  }


  .full-group{
    width: 100%;
    position:relative;
    .form-control{
      width: 100%;
    }

    .selectize-input{
      min-height: 40px;
      font-size: 16px;
      padding: 12px 6px 12px 6px;
      height: 64px;
      line-height: 40px;



    }
  }

  .search-icon{
    position:absolute;
    top: 6px;
    left: 10px;
    z-index: 1000;
    i{
      color: @brand-color;
      font-size: 26px;
    }
  }
}
.search-form-wrapper{

  //background:rgba(255,255,255,.6);
  //background:rgba(0,0,0,.3);
  //background: #fff;
  //background: #fff;
//  padding: 15px 15px 0 15px;

  .btn-primary{
    background: @brand-color;
    border:none;

    &:active{
      background: darken(@brand-color, 10%);
    }
  }
}

.search-form-vertical{
  .btn-primary{
    background: @mycolor-red;
    border:none;

    &:active{
      background: darken(@mycolor-red, 10%);
    }
  }
}


@media(max-width: @screen-sm) {
  .half-splash {
    .search-form-wrapper {
      margin: 15px auto 15px;
     width: 100%;
      padding: 20px;
    }


  }

}

.search-inner .input-short{
   // height: 64px;
  border-radius:0;
  button{
   // line-height: 40px;
    border-radius:0;

    .filter-option{
      padding-right: 10px;
    }
  }
}
.search-inner .input-red{
  &.form-control{
    background: @mycolor-red;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

.search-inner .input-blue{
  &.form-control{
    background: @mycolor-blue;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}


.search-inner .input-transparent{
  &.form-control{
    background: none;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

@media(max-width: @screen-sm){

  .shortsearchform .selectize-input, .shortsearchform .selectize-control.multi .selectize-input.has-items {
     // min-width: 0;
    width:100%;
    }
  .shortsearchform {
    .shortsearch-inner-wrapper {
      width: 100%;
      display: block;
    }
  }



}
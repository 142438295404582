


a.text-icon-link{
  text-decoration: none !important;

  &:hover{
    .text-icon{
   // background: @brand-color3;
      h3, p, i{
       // color: #fff;
      }
    }
  }
}
.text-icon {
  display:block;
  position:relative;
  overflow: visible;
  margin-top:20px;
  //margin-bottom: 60px;
 // background: #f6f7f8;
 // padding: 15px 15px 20px 15px;
  transition: all 0.5s ease;

  .text-icon-icon {
    text-align: center;
    display: block;
    position:relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    opacity: .8;
    background: #fff;
    border-radius:50%;
    .flaticon:before, .flaticon:after{
      font-size: 70px;
    }
    i{
      font-size: 40px !important;
      text-align: center;
      line-height: 100px;

    }

    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
      font-size: 45px;
    }
  }

  h3{
    margin: 15px 0 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  }


  .text-icon-body{
  //  overflow:hidden;
    min-height: 100px;
    text-align: center;

    p{
    font-size: 14px;
      opacity:.8;
    }
  }
}

.divider-third .text-icon h3{
  color: #fff;
}